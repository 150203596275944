export enum Pages {
  Profile = "profile",
  Skill = "skill",
  Project = "project",
  Experience = "experience",
  Team = "team",
  Employer = "employer",
  Contact = "contact",
}

export enum Mode {
  Light = "light",
  Dark = "dark",
}
