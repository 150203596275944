import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Pages } from "./utils/enums/enums";

const EmployerPage = lazy(() => import("./pages/EmployerPage"));
const ExperiencePage = lazy(() => import("./pages/ExperiencePage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const ProjectPage = lazy(() => import("./pages/ProjectPage"));
const RootLayout = lazy(() => import("./pages/Root"));
const SkillPage = lazy(() => import("./pages/SkillPage"));
const TeamPage = lazy(() => import("./pages/TeamPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<>Loading...</>}>
        <RootLayout />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<>Loading...</>}>
        <NotFoundPage />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<>Loading...</>}>
            <ProfilePage />
          </Suspense>
        ),
      },
      {
        path: Pages.Skill,
        element: (
          <Suspense fallback={<>Loading...</>}>
            <SkillPage />
          </Suspense>
        ),
      },
      {
        path: Pages.Project,
        element: (
          <Suspense fallback={<>Loading...</>}>
            <ProjectPage />
          </Suspense>
        ),
      },
      {
        path: Pages.Experience,
        element: (
          <Suspense fallback={<>Loading...</>}>
            <ExperiencePage />
          </Suspense>
        ),
      },
      {
        path: Pages.Team,
        element: (
          <Suspense fallback={<>Loading...</>}>
            <TeamPage />
          </Suspense>
        ),
      },
      {
        path: Pages.Employer,
        element: (
          <Suspense fallback={<>Loading...</>}>
            <EmployerPage />
          </Suspense>
        ),
      },
      {
        path: Pages.Contact,
        element: (
          <Suspense fallback={<>Loading...</>}>
            <ContactPage />
          </Suspense>
        ),
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
